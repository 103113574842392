var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vxe-modal", {
    ref: "vxe-" + _vm.vehicleId,
    staticClass: "my-modal",
    attrs: {
      width: "20%",
      height: "23%",
      title: _vm.cameraTitle,
      "show-zoom": "",
      resize: "",
      "lock-view": false,
      mask: false,
      "esc-closable": true,
      position: _vm.position,
      transfer: "",
    },
    on: { close: _vm.handleCloaseCamera, hide: _vm.handleCloaseCamera },
    scopedSlots: _vm._u(
      [
        _vm.isSyncVideo
          ? {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "vxe-modal--header-title",
                      class: [_vm.isInfreeze ? "video-in-freeze" : ""],
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.cameraTitle) +
                          _vm._s(_vm.isInfreeze ? "-视频解冻中" : "")
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "vxe-modal--header-right" }, [
                    _vm.index === 0
                      ? _c(
                          "span",
                          {
                            staticClass: "icon-span",
                            attrs: { title: "展示全部" },
                          },
                          [
                            _c("a-icon", {
                              attrs: { type: "caret-down" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleShowAll.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.index === 0
                      ? _c(
                          "span",
                          {
                            staticClass: "icon-span",
                            attrs: { title: "播放全部" },
                          },
                          [
                            _c("a-icon", {
                              attrs: { type: "play-circle" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handlePlayAll.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "span",
                          {
                            staticClass: "icon-span",
                            attrs: { title: "播放视频" },
                          },
                          [
                            _c("a-icon", {
                              attrs: {
                                type: _vm.syncPlayChecked
                                  ? "pause-circle"
                                  : "play-circle",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handlePlay.apply(null, arguments)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                    _c("i", {
                      staticClass: "vxe-modal--zoom-btn trigger--btn",
                      class: _vm.zoomIn
                        ? "vxe-icon-maximize"
                        : "vxe-icon-square",
                      attrs: { title: "vxe.modal.zoomIn" },
                      on: { click: _vm.vxeZoom },
                    }),
                    _vm.index > 0
                      ? _c("i", {
                          staticClass:
                            "vxe-modal--close-btn trigger--btn vxe-icon-close",
                          attrs: { title: "vxe.modal.close" },
                          on: { click: _vm.vxeClick },
                        })
                      : _vm._e(),
                  ]),
                ]
              },
              proxy: true,
            }
          : null,
        {
          key: "default",
          fn: function () {
            return [
              !_vm.isSyncVideo ||
              (_vm.cameraVisible &&
                (_vm.index === 0 || _vm.syncPlayChecked) &&
                _vm.reMounted &&
                !_vm.noPlayTimer)
                ? _c("revo-video-player", {
                    ref: "player",
                    attrs: {
                      cameraTitle: _vm.cameraTitle,
                      "is-live":
                        _vm.isLive === undefined ? !_vm.videoUrl : _vm.isLive,
                      vehicleId: _vm.vehicleId,
                      progressMarkers: _vm.progressMarkers,
                      vehicleCameraDeviceInfo: _vm.vehicleCameraDeviceInfo,
                      seekTime: _vm.seekTime,
                      isSyncVideo: _vm.isSyncVideo,
                      direction: _vm.cameraId,
                      url: _vm.showVedioUrl,
                      index: _vm.index,
                      videoType: _vm.videoType,
                    },
                    on: {
                      handleReady: _vm.handleReady,
                      handleError: _vm.handleError,
                      handleEnded: _vm.handleEnded,
                      isErrorPlay: _vm.isErrorPlay,
                    },
                  })
                : _c("div", { staticClass: "no-play-block" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          !_vm.syncPlayChecked && _vm.index !== 0
                            ? "点击右上角播放按钮进行播放"
                            : "没有可播放的对应轨迹视频段"
                        ) +
                        " "
                    ),
                  ]),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.cameraVisible,
      callback: function ($$v) {
        _vm.cameraVisible = $$v
      },
      expression: "cameraVisible",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }