<template>
  <!-- <div :id="cameraId" style="height:100%;"> -->
  <vxe-modal
    v-model="cameraVisible"
    :ref="'vxe-' + vehicleId"
    width="20%"
    height="23%"
    class="my-modal"
    :title="cameraTitle"
    @close="handleCloaseCamera"
    @hide="handleCloaseCamera"
    show-zoom
    resize
    :lock-view="false"
    :mask="false"
    :esc-closable="true"
    :position="position"
    transfer
  >
    <template v-if="isSyncVideo" #header>
      <!-- 轨迹同步视频：视频正在解冻中加上文字提醒 -->
      <div class="vxe-modal--header-title" :class="[isInfreeze?'video-in-freeze':'']">{{ cameraTitle }}{{ isInfreeze?'-视频解冻中':'' }}</div>
      <div class="vxe-modal--header-right">
        <span class="icon-span" v-if="index === 0" title="展示全部">
          <a-icon @click.stop="handleShowAll" type="caret-down" />
        </span>
        <span class="icon-span" v-if="index === 0" title="播放全部">
          <a-icon @click.stop="handlePlayAll" :type="'play-circle'" />
        </span>
        <span class="icon-span" v-else title="播放视频">
          <a-icon @click.stop="handlePlay" :type="syncPlayChecked ? 'pause-circle' : 'play-circle'" />
        </span>
        <!-- <i title="vxe.modal.right" class="vxe-modal--right-btn vxe-icon-arrow-double-right" @click="toModalRight"></i> -->
        <i
          title="vxe.modal.zoomIn"
          @click="vxeZoom"
          :class="zoomIn ? 'vxe-icon-maximize' : 'vxe-icon-square'"
          class="vxe-modal--zoom-btn trigger--btn"
        ></i>
        <i
          title="vxe.modal.close"
          v-if="index > 0"
          @click="vxeClick"
          class="vxe-modal--close-btn trigger--btn vxe-icon-close"
        ></i>
      </div>
    </template>
    <template #default>
      <revo-video-player
        ref="player"
        v-if="!isSyncVideo || (cameraVisible && (index === 0 || syncPlayChecked) && reMounted && !noPlayTimer)"
        @handleReady="handleReady"
        @handleError="handleError"
        @handleEnded="handleEnded"
        @isErrorPlay="isErrorPlay"
        :cameraTitle="cameraTitle"
        :is-live="isLive === undefined ? !videoUrl : isLive"
        :vehicleId="vehicleId"
        :progressMarkers="progressMarkers"
        :vehicleCameraDeviceInfo="vehicleCameraDeviceInfo"
        :seekTime="seekTime"
        :isSyncVideo="isSyncVideo"
        :direction="cameraId"
        :url="showVedioUrl"
        :index="index"
        :videoType="videoType"
      />
      <div v-else class="no-play-block">
        {{ !syncPlayChecked && index !== 0 ? '点击右上角播放按钮进行播放' : '没有可播放的对应轨迹视频段' }}
      </div>
    </template>
  </vxe-modal>
  <!-- </div> -->
</template>

<script>
import get from 'lodash/get'
// import { handleModeData } from '@/translatedata/systemData.js'
import allIcons from '@/core/icons'
import RevoVideoPlayer from './RevoVideoPlayer'
import api from '@/api/iot/vehicle'

export default {
  name: 'MonitorVideoModal',
  props: {
    isLive: {
      type: Boolean,
      default: undefined
    },
    videoUrl: {
      type: String,
      default: ''
    },
    cameraInfo: {
      type: Object,
      default: () => {}
    },
    cameraId: {
      type: String,
      default: ''
    },
    streamId: {
      type: String,
      default: ''
    },
    position: {
      type: Object,
      default: () => {}
    },
    progressMarkers: {
      type: Array,
      default: undefined
    },
    seekTime: {
      type: Number,
      default: undefined
    },
    index: {
      type: Number,
      default: undefined
    },
    videoType: {
      type: String,
      default: 'rtmp'
    },
    vehicleCameraDeviceInfo: {
      type: Object,
      default: () => {}
    },
    isSyncVideo: {
      type: Boolean,
      default: false
    },
    noPlayTimer: {
      type: Boolean,
      default: false
    },
    // 视频是否正在解冻中
    isInfreeze: {
      type: Boolean,
      default: false
    }
  },
  components: {
    RevoVideoPlayer
  },
  computed: {
    // videoList () {
    //   // return ['front', 'back', 'left', 'right']
    //   return ['front']
    // },
    vehicleId() {
      return get(this.cameraInfo, 'vehicleId', '')
    },
    cameraTitle() {
      return '视频-' + this.cameraInfo.cameraName
    },
    cameraVisible() {
      console.log('modal **** cameraVisible ****', this.cameraInfo)
      return this.cameraInfo.cameraVisible
    },
    showVedioUrl() {
      console.log('this.videoUrl', this.videoUrl)
      if (this.videoUrl) {
        return this.videoUrl
      } else if (this.cameraInfo) {
        if (this.cameraInfo.cameraAddr.indexOf('http') === 0) {
          return this.cameraInfo.cameraAddr
        } else {
          return this.url + this.cameraInfo.cameraAddr
        }
      } else {
        return ''
      }
    }
  },
  watch: {
    'cameraInfo.cameraVisible'(val) {
      if (val) {
        this.getCurPosition()
      }
    }
  },
  data() {
    return {
      api,
      cameraIcon: allIcons.cameraIcon,
      zoomIn: false,
      // cameraVisible: false,
      url: api.videoUrlList[0],
      syncPlayChecked: false,
      isSyncPlayAll: false,
      reMounted: true
    }
  },
  created() {
    if (this.cameraInfo.cameraAddr.indexOf('http') === 0) {
      this.showVedioUrl = this.cameraInfo.cameraAddr
      console.log('RVMMMM ******', this.showVedioUrl)
    }
  },
  mounted() {
    if (this.cameraInfo.cameraVisible) {
      this.getCurPosition()
    }
  },
  destroyed() {},
  methods: {
    vxeZoom() {
      // 窗口缩放
      this.$refs['vxe-' + this.vehicleId].zoom()
      this.zoomIn = !this.$refs['vxe-' + this.vehicleId].isMaximized()
    },
    getCurPosition() {
      this.$nextTick(() => {
        setTimeout(() => {
          if (!this.nowPosition) {
            this.nowPosition = this.$refs['vxe-' + this.vehicleId].getPosition()
            console.log('this.nowPosition', this.nowPosition)
          }
        }, 0)
      })
    },
    handleReady(e) {
      this.$emit('handleReady', e)
    },
    handleError(e) {
      // this.reloadVedioEle()
      this.$emit('handleError', e, this.streamId)
    },
    handleEnded() {
      this.$emit('handleEnded', this.streamId)
    },
    isErrorPlay(type) {
      this.$emit('isErrorPlay', type, this.streamId)
    },
    handleCloaseCamera() {
      this.cameraVisible = false
      console.log('this.cameraId', this.cameraId)
      console.log('this.streamId', this.streamId)
      this.$emit('closeModalCamera', this.cameraId, this.streamId)
    },
    toModalRight() {
      console.log('this.nowPosition', this.nowPosition)
      if (this.nowPosition) {
        this.$refs['vxe-' + this.vehicleId].setPosition(this.nowPosition.top, this.nowPosition.left)
      }
    },
    vxeClick() {
      this.$refs['vxe-' + this.vehicleId].close()
    },
    pause() {
      this.$refs.player && this.$refs.player.pause()
    },
    play() {
      this.$refs.player && this.$refs.player.isPlay()
    },
    seek(time) {
      // time 单位为秒
      this.$refs.player && this.$refs.player.seek(time)
    },
    setSpeed(speed) {
      this.$refs.player && this.$refs.player.setSpeed(speed)
    },
    getDuration() {
      return this.$refs.player ? this.$refs.player.getDuration() : ''
    },
    handleShowAll() {
      this.$emit('handleShowAll')
    },
    handlePlayAll() {
      this.$emit('handlePlayAll')
      this.isSyncPlayAll = true
    },
    handlePlay() {
      this.syncPlayChecked = !this.syncPlayChecked
      this.$emit('handlePlay', this.streamId, this.syncPlayChecked)
    },
    trunSyncPlayOn() {
      this.syncPlayChecked = true
    },
    trunSyncPlayOff() {
      this.syncPlayChecked = false
    },
    reloadVedioEle() {
      this.reMounted = false
      this.$nextTick(() => {
        this.reMounted = true
      })
    }
  }
}
</script>

<style lang="less">
.no-play-block {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: #000;
  color: fff;
}

.icon-span {
  margin-left: 5px;
  font-size: 15px;
}
.video-in-freeze {
  color: red;
}
</style>
